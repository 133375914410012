import { domAnimation, LazyMotion } from 'framer-motion';
import React, { ReactElement, ReactNode, useMemo } from 'react';
import { useLocation } from '@gatsbyjs/reach-router';
import './app.scss';
import './assets/styles/styles.scss';
import Footer from './footer/footer';
import Header from './header/header';
import Privacy from './privacy/privacy';
import ApiProvider from './shared/api/api-provider';
import CartProvider from './shared/cart/cart-provider';
import { LIFTED_ELEMENT_ID } from './shared/hooks/use-lifted-content';
import TrackingProvider from './shared/tracking/tracking-provider';
import Verification from './verification/verification';
import { graphql, useStaticQuery } from 'gatsby';
import { BuilderComponent } from '@builder.io/react';

import { PayPalScriptProvider } from '@paypal/react-paypal-js';


const initialPaypalOptions = {
  'clientId': process.env.GATSBY_PAYPAL_CLIENT_ID as string,
  'currency': 'EUR',
  'debug': false,
  'disableFunding': 'sepa,giropay,sofort',
  'components': 'buttons,messages,marks,payment-fields',
};

// TEST

type Props = {
  children: ReactNode;
};

const routesWithoutHeader = [
  "/checkout/customer",
  "/checkout/shipping",
  "/checkout/payment",
  "/checkout/confirmation"
]

export default function App({ children }: Props): ReactElement {

  const { pathname } = useLocation()

  const normalizedPathname = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;

  const showHeader = useMemo(() => {
    // Using 'some' instead of 'find' for better readability and boolean result
    const shouldHide = routesWithoutHeader.some((route) => route === normalizedPathname);

    // Check if the path exactly matches one in the routesWithoutHeader array
    if (shouldHide && normalizedPathname !== "") return false;

    return true;
  }, [normalizedPathname]);

  const newsletterPopover = useStaticQuery(graphql`
    query {
      allBuilderModels {
        newsletterPopover(
          limit: 1
        ) {
          content
        }
      }
    }
  `);

  return (
    <TrackingProvider>
      <div className="app block block--vertical">
        <LazyMotion features={domAnimation}>
          <ApiProvider>
            <Verification>
              <PayPalScriptProvider options={initialPaypalOptions}>
                <CartProvider>
                  {showHeader && <Header />}

                  <div className="site-content">
                    {children}
                  </div>

                  {showHeader && <Footer />}

                  {newsletterPopover?.allBuilderModels?.newsletterPopover[0] && (
                    <BuilderComponent
                      options={{ enrich: true }}
                      model="newsletter-popover"
                      content={newsletterPopover?.allBuilderModels?.newsletterPopover[0]?.content}
                    />
                  )}
                </CartProvider>
              </PayPalScriptProvider>

              <Privacy />
            </Verification>

            <div id={LIFTED_ELEMENT_ID} />
          </ApiProvider>
        </LazyMotion>
      </div>
    </TrackingProvider>
  );
}

