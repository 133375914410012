import React, { ReactElement, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ApiContext } from '../shared/api/api-provider';
import useLiftedContent from '../shared/hooks/use-lifted-content';
import StorageKeys from '../shared/storage';

type Props = {
  children: ReactNode;
};

export default function Verification({ children }: Props): ReactElement | null {
  const [declined, setDeclined] = useState<boolean>();
  const [verified, setVerified] = useState<boolean>();

  const { userId: user } = useContext(ApiContext);

  const target = useLiftedContent();

  useEffect(() => {
    setVerified(!!localStorage.getItem(StorageKeys.Verified));
  }, []);

  const verify = useCallback(() => {
    localStorage.setItem(StorageKeys.Verified, 'true');
    setVerified(true);
  }, []);

  const renderQuestion = useCallback(() => {
    return (
      <>
        <h1>
          <span className="fg-beige">Bist</span> du schon 18?
        </h1>

        <p className="my-7">
          Unsere Inhalte dürfen nur von Erwachsenen betrachtet werden.
        </p>

        <div className="block block--justify-between">
          <button
            type="button"
            className="button button--unstyled text-heading text-xxxl fg-white"
            onClick={setDeclined.bind(null, true)}
          >
            Nein
          </button>

          <button
            type="button"
            className="button button--unstyled text-heading text-xxxl"
            onClick={verify}
          >
            Ja
          </button>
        </div>
      </>
    );
  }, [verify]);

  const renderProhibited = useCallback(() => {
    return (
      <>
        <h1>
          Du bist zu <span className="fg-beige">jung</span>
        </h1>

        <p className="mt-7">
          Dein Alter erlaubt es dir nicht diese Inhalte zu betrachten.
          Bitte kehre zurück, wenn du alt genug bist.
        </p>
      </>
    );
  }, []);

  if(verified === undefined) {
    return null;
  }

  return (<>
    {children}
    {target && !(user || verified) && createPortal((
      <>
        <div className="dialog__background"></div>
        <div style={{zIndex: "1200"}} className="dialog">
          {declined ? renderProhibited() : renderQuestion()}
        </div>
      </>
    ), target)}
  </>);
}
